import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'
import { post } from '@rails/request.js'


export default class extends Controller {
  static targets = [ "" ]
  static values = {
    duration: Number,
    wait: Number,
    exit: String,
    clickToExit: Boolean,
    width: Number,
    height: Number,
    impressionsUrl: String,
  }
  

  idleTime = 0
  intervalId = null
  screenSaverOn = false
  swiper = null

  initialize(){
    
  }

  connect() {
    this.startIdle()
  }

  disconnect() {
    this.stopIdle();
  }

  startIdle(){
    if(this.intervalId){
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.timerIncrement()
    }, 1000); // 1 Second

  }
  stopIdle(){
    clearInterval(this.intervalId);
    this.idleTime = 0;
    if(this.swiper){
      this.swiper.destroy()
    }
  }

  timerIncrement() {
    this.idleTime = this.idleTime + 1;
    if (this.idleTime > this.waitValue-1 && !this.screenSaverOn) { // 10 Seconds
        this.startScreenSaver();
    }
  }

  stopScreenSaver(){
    if(this.screenSaverOn){
      this.screenSaverOn = false;
      const kioskFooter = document.getElementById("kioskFooter");
      if (kioskFooter) {
        kioskFooter.classList.add("d-none");
      }

      const swiperContainer = document.getElementById("swiperContainer");
      if (swiperContainer) {
        swiperContainer.classList.add("d-none");
      }
    
      Turbo.visit(this.exitValue) 
    }
    else if(this.clickToExitValue){
      Turbo.visit(this.exitValue)
    }
  }

  startScreenSaver(){
    this.screenSaverOn = true
    this.stopIdle()
    this.randomizeSlides()  
    this.swiper = new Swiper('.swiper-container', {
      pagination: false,
      paginationClickable: false,
      autoplay: {
        delay: this.durationValue * 1000,
      },
      loop: true,
      allowTouchMove: false,
      speed: 1000,
      slidesPerView: 1,
      slidesPerGroup: 1,
      parallax: false,
      width: this.widthValue,
      height: this.heightValue,
      centeredSlides: true,
    });
    this.swiper.on('slideChangeTransitionStart', this.handleSlideChange.bind(this));
    const kioskFooter = document.getElementById("kioskFooter");
    if (kioskFooter) {
      kioskFooter.classList.remove("d-none");
    }

    const swiperContainer = document.getElementById("swiperContainer");
    if (swiperContainer) {
      swiperContainer.classList.remove("d-none");
    }
  }  

  randomizeSlides() {
    var slides = document.querySelectorAll('.random-slide');
    var slideArray = Array.prototype.slice.call(slides);
    slideArray.sort(function () { return 0.5 - Math.random() });
    for (var i = 0; i < slideArray.length; i++) {
        slides[0].parentNode.appendChild(slideArray[i]);
    }
  }

  async postImpression(banner_ad_id){
    let data = JSON.stringify({
      impressions: {
        impression_attributes: [
          { trackable_id: banner_ad_id, trackable_type: 'Advertisement' }
        ]
      }
    })
 
    const response = await post(this.impressionsUrlValue, { body: data, responseKind: "json" })
  }

  handleSlideChange(swiper) {
    // Use realIndex to get the actual slide index in loop mode
    const realIndex = swiper.realIndex;

    // Access the actual slide element
    const currentSlide = swiper.slides[swiper.activeIndex];

    // Get the HTML ID of the current slide
    const slideId = currentSlide.getAttribute("id");

    if(slideId != "main-splash-page"){
      var banner_ad_id = (slideId).split('_').pop()
      this.postImpression(banner_ad_id)
    }
  }
}

