// app/javascript/custom/companion.js

if (window.location.pathname.startsWith("/pub/kiosks/")) {
  const pwaVersion = document.querySelector('meta[name="pwa-version"]')?.getAttribute('content') || "1"; // Fallback to version "1"
  const CACHE_VERSION = pwaVersion;
  console.log(`PWA Version: ${pwaVersion}`);
  // Determine the type of kiosk (display or touch) from the meta tag
  const kioskType = document.querySelector('meta[name="kiosk-type"]')?.getAttribute('content');
  const kioskToken = document.querySelector('meta[name="kiosk-token"]')?.getAttribute('content');


  if (!kioskType || !kioskToken) {
    console.error("Kiosk type or token missing. Unable to register service worker.");
    return;
  }

  // Select the appropriate service worker based on the kiosk type
  let serviceWorkerPath;
  if (kioskType === "display") {
    serviceWorkerPath = `/service-worker-display.js?v=${CACHE_VERSION}&kiosk_token=${kioskToken}`;
  } else if (kioskType === "touch") {
    serviceWorkerPath = `/service-worker-touch.js?v=${CACHE_VERSION}&kiosk_token=${kioskToken}`;
  } else {
    console.error("Unknown kiosk type. Unable to register service worker.");
    return;
  }

  // Register the service worker
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .register(serviceWorkerPath, { scope: "/" })
      .then(() => navigator.serviceWorker.ready)
      .then((registration) => {
        console.log(`Service worker (${kioskType}) registered successfully.`);
        
        // Optional: Register background sync if supported
        if ("SyncManager" in window) {
          registration.sync.register("sync-forms").catch((err) => {
            console.error("Failed to register sync:", err);
          });
        } else {
          console.warn("Background sync is not supported in this browser.");
        }
      })
      .catch((err) => {
        console.error("Service worker registration failed:", err);
      });
  } else {
    console.error("Service workers are not supported in this browser.");
  }
}
