import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'

export default class extends Controller {
  static targets = []
  static values = {
    duration: Number,
    width: Number,
    height: Number,
    impressionsUrl: String,
  }
  
  swiper = null

  connect() {
    this.startAds()
    this.scheduleImpressionSync()
  }

  disconnect() {
    this.stopImpressionSync()
  }

  startAds() {
    this.randomizeSlides()  

    this.swiper = new Swiper('.swiper-container', {
      pagination: false,
      autoplay: {
        delay: this.durationValue * 1000,
      },
      loop: true,
      allowTouchMove: false,
      speed: 1000,
      slidesPerView: 1,
      slidesPerGroup: 1,
      width: this.widthValue,
      height: this.heightValue,
      centeredSlides: true,
    });

    this.swiper.on('slideChangeTransitionStart', this.handleSlideChange.bind(this));
  }

  randomizeSlides() {
    const slides = Array.from(document.querySelectorAll('.random-slide'));
    slides.sort(() => Math.random() - 0.5).forEach(slide => slide.parentElement.appendChild(slide));
  }

  handleSlideChange(swiper) {
    const currentSlide = swiper.slides[swiper.activeIndex];
    const slideId = currentSlide.getAttribute("id");

    if (slideId && slideId !== "main-splash-page") {
      const bannerAdId = slideId.split('_').pop();
      this.storeImpression(bannerAdId);
    }
  }

  storeImpression(bannerAdId) {
    const impressions = JSON.parse(localStorage.getItem('impressions')) || [];
    impressions.push({
      trackable_id: bannerAdId,
      trackable_type: 'Advertisement',
      timestamp: Date.now(),
    });
    localStorage.setItem('impressions', JSON.stringify(impressions));
  }

  async syncImpressions() {
    const impressions = JSON.parse(localStorage.getItem('impressions')) || [];
    if (impressions.length === 0) return; // Skip if no impressions
  
    try {
      await fetch(this.impressionsUrlValue, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          impressions: {
            impression_attributes: impressions
          }
        }),
      });
      localStorage.setItem('impressions', JSON.stringify([])); // Clear after sync
    } catch (error) {
      console.error("Failed to sync impressions:", error);
    }
  }
  

  scheduleImpressionSync() {
    this.impressionSyncInterval = setInterval(() => {
      this.syncImpressions();
    }, 60000); // Sync every 60 seconds
  }

  stopImpressionSync() {
    if (this.impressionSyncInterval) {
      clearInterval(this.impressionSyncInterval);
    }
  }
}
