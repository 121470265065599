import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle'
import { post } from '@rails/request.js'



export default class extends Controller {
  static targets = [ "" ]
  static values = {
    duration: Number,
    width: Number,
    height: Number,
    impressionsUrl: String,
  }
  

  idleTime = 0
  intervalId = null
  screenSaverOn = false
  swiper = null

  initialize(){
    
  }

  connect() {
    this.startSlideshow()
  }

  disconnect() {
    this.stopSlideshow();
  }

  stopSlideshow(){
    this.screenSaverOn = false;
    this.swiper.destroy()
  }

  startSlideshow(){
    this.screenSaverOn = true
    this.randomizeSlides()  
    this.swiper = new Swiper('.banner-ads-swiper-container', {
      pagination: false,
      paginationClickable: false,
      autoplay: {
        delay: this.durationValue * 1000,
      },
      loop: true,
      allowTouchMove: false,
      speed: 1000,
      slidesPerView: 1,
      slidesPerGroup: 1,
      parallax: false,
      width: this.widthValue,
      height: this.heightValue*0.3125,
      centeredSlides: true,
    });
    this.swiper.on('afterInit', function (event) {
      // console.log("Started")
      // console.log(event.slides[event.snapIndex])
    });
    this.swiper.on('slideChangeTransitionStart', this.handleSlideChange.bind(this));
    document.getElementById("bannerAdsSwiperContainer").classList.remove("d-none")
  }  

  randomizeSlides() {
    var slides = document.querySelectorAll('.banner-ads-random-slide');
    var slideArray = Array.prototype.slice.call(slides);
    slideArray.sort(function () { return 0.5 - Math.random() });
    for (var i = 0; i < slideArray.length; i++) {
        slides[0].parentNode.appendChild(slideArray[i]);
    }
  }

  async postImpression(banner_ad_id){
    let data = JSON.stringify({
      impressions: {
        impression_attributes: [
          { trackable_id: banner_ad_id, trackable_type: 'Advertisement' }
        ]
      }
    })
 
    const response = await post(this.impressionsUrlValue, { body: data, responseKind: "json" })
  }

  handleSlideChange(swiper) {
    // Use realIndex to get the actual slide index in loop mode
    const realIndex = swiper.realIndex;

    // Access the actual slide element
    const currentSlide = swiper.slides[swiper.activeIndex];

    // Get the HTML ID of the current slide
    const slideId = currentSlide.getAttribute("id");

    if(slideId != "main-splash-page"){
      var banner_ad_id = (slideId).split('_').pop()
      this.postImpression(banner_ad_id)
    }
  }
}

